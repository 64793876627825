<template>
    <ManutencaoIndicadoresList />
</template>

<script>
import ManutencaoIndicadoresList from '../../components/cadastro/ManutencaoIndicadoresList.vue';

export default {
    name: 'ManutencaoIndicadores',
    components: { ManutencaoIndicadoresList }
}
</script>