import axios from "axios";
import ApiUrl from '../config/ApiUrl';

class ManutencaoIndicadoresSerivce {

    buscarPorId(id) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/indicador-valor/${ id }`);
    }

    buscarComPaginacao(params = { situacao: 'ATIVO', page: 0, size: 10,  sort: 'id,asc' }) {
        return axios.get(`${ ApiUrl.vg }api/genesis-vg/v1/indicador-valor/buscar-com-paginacao`, { params });
    }

    salvar(data) {
        const config = {
            headers: { 'content-type' : 'application/json' }
        }
        return axios.post(`${ ApiUrl.vg }api/genesis-vg/v1/indicador-valor`, data, config);
    }

    ativar(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/indicador-valor/ativar/${ id }`);
    }

    inativar(id) {
        return axios.put(`${ ApiUrl.vg }api/genesis-vg/v1/indicador-valor/inativar/${ id }`);
    }
}

export default new ManutencaoIndicadoresSerivce();