<template>
    <div class="card">
        <div class="flex align-items-center">
           <i class="pi pi-building" style="font-size: 1.4rem"></i> 
           <h3 style="margin:0px 5px;">Manutenção de Indicadores</h3>
        </div>

        <hr />

        <DataTable 
            responsiveLayout="stack"
            :value="data"
            v-model:selection="selecionado"
            selectionMode="single"
            showGridlines
            @sort="sort($event)">

            <template #header>
                <div class="flex align-items-center">
                    <div class="field mr-2" style="width: 15rem;">
                        <label for="estabelecimento">
                            <strong>Sigla</strong>
                        </label>
                        <InputText v-model="filter.sigla" class="w-full" />
                    </div>

                    <div class="field mr-2" style="width: 15rem;">
                        <label for="estabelecimento">
                            <strong>Descrição</strong>
                        </label>
                        <InputText v-model="filter.descricao" class="w-full" />
                    </div>

                    <div class="mr-2 mt-2">
                        <div class="p-inputgroup">
                            <Button
                                label="Filtrar"
                                icon="pi pi-filter"
                                class="p-button-outlined p-button-info"
                                @click="carregarDados()"
                            ></Button>

                            <Button 
                                title="Limpar Filtro"
                                icon="pi pi-filter-slash"
                                class="p-button-outlined p-button-info"
                                style="margin-left: 1px;"
                                @click="limparFiltro()"
                            ></Button>
                        </div>
                    </div>

                    <Button
                        v-if="filter.situacao == 'ATIVO'"
                        type="button"
                        icon="pi pi-ban"
                        label="Listar inativos"
                        class="p-button-outlined p-button-danger mr-2 mt-2"
                        @click="filter.situacao = 'INATIVO'; carregarDados()"
                    ></Button>

                    <Button
                        v-if="filter.situacao == 'INATIVO'"
                        type="button"
                        icon="pi pi-check-circle"
                        label="Listar ativos"
                        class="p-button-outlined mr-2 mt-2"
                        @click="filter.situacao = 'ATIVO'; carregarDados()"
                    ></Button>

                    <Button 
                        label="Cadastrar"
                        icon="pi pi-plus"
                        class="p-button-info mt-2"
                        @click="cadastrar()"
                    ></Button>
                </div>
            </template>

            <template #empty>
                Nenhum registro encontrado.
            </template>

            <template #loading>
                Carregando... Por favor, aguarde.
            </template>

            <Column field="id" header="Indicador" />
            <Column field="sigla" header="Sigla" />
            <Column field="descricao" header="Descrição" />
            <Column field="tipo" header="Tipo">
                <template #body="{ data, field }">
                    <span v-if="data[field] == 'DOLAR'">Dólar</span>
                    <span v-if="data[field] == 'PERCENTUAL'">Percentual</span>
                    <span v-if="data[field] == 'REAL'">Real</span>
                </template>
            </Column>
            <Column field="aFixar" header="A Fixar">
                <template #body="{ data, field }">
                    {{ data[field] ? "Sim" : "Não" }}
                </template>
            </Column>
            <Column field="utilizacao" header="Utilização">
                <template #body="{ data, field }">
                    <span v-if="data[field] == 'CONTRATO'">Contrato</span>
                    <span v-if="data[field] == 'COTACAO'">Cotação</span>
                    <span v-if="data[field] == 'FORMULA'">Fórmula</span>
                </template>
            </Column>
            <Column header="Ações" style="width:9rem;" class="centralizar-titulo-tabela">
                <template #body="{ data }">
                    <div class="flex-none">
                        <Button 
                            title="Visualizar histórico"
                            icon="pi pi-list"
                            class="p-button-rounded p-button-warning flex-none mr-2"
                            @click="visualizarHistorico(data)"
                        ></Button>

                        <Button
                            title="Editar"
                            icon="pi pi-pencil"
                            class="p-button-rounded p-button-info flex-none mr-2"
                            v-if="data.ativo"
                            @click="editar(data.id)"
                        ></Button>

                        <Button 
                            v-if="data.ativo"
                            title="Inativar"
                            class="p-button-rounded flex-none p-button-danger"
                            icon="pi pi-ban"
                            @click="confirmarAtivarOuInativar(data)"
                        ></Button>

                        <Button 
                            v-if="!data.ativo"
                            title="Ativar"
                            class="p-button-rounded flex-none p-button-info"
                            icon="pi pi-check-circle"
                            @click="confirmarAtivarOuInativar(data)"
                        ></Button>
                    </div>
                </template>
            </Column>
        </DataTable>

        <Paginator
            v-model:first="primeiraLinha"
            :rows="qtdRegistroPorPagina"
            :total-records="totalRegistro"
            :rowsPerPageOptions="[5, 10, 20, 30]"
            @page="onPage" />

    </div>

    <Dialog v-model:visible="ativarOuInativarDialog" :style="{width: '300px'}" header="Alerta" :modal="true">
        <div class="flex align-items-center justify-content-center">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
            <span>Tem certeza que deseja <strong>{{ selecionado.ativo ? 'inativar' : 'ativar' }}</strong> o contrato: <strong>{{ selecionado.id }}</strong>?</span>
        </div>
        <template #footer>
            <Button label="Não" icon="pi pi-times" class="p-button-text" @click="ativarOuInativarDialog = false" />
            <Button label="Sim" icon="pi pi-check" class="p-button-text p-button-danger" @click="ativarOuInativar()" />
        </template>
    </Dialog>

    <ManutencaoIndicadoresForm
        :id="idIndicador"
        :visivel="formularioDialog"
        @fechar="fecharFormulario"
    />

    <ModalHistoricoGenerico
        codigoPagina="INDICADOR_VALOR"
        :id="selecionado.id"
        :descricao="descricaoHistoricoDialog"
        :visivel="historicoDialog"
        @fechar="ocultarHistorico()"
    />
</template>

<script>
import StorageService from '../../service/StorageService';
import ModalHistoricoGenerico from '../ModalHistoricoGenerico.vue';
import ManutencaoIndicadoresForm from './ManutencaoIndicadoresForm.vue';
import ManutencaoIndicadoresSerivce from '../../service/ManutencaoIndicadoresSerivce';

export default {
    name: 'ManutencaoIndicadoresList',
    components: { ManutencaoIndicadoresForm, ModalHistoricoGenerico },
    data() {
        return {
            pagina: 0,
            primeiraLinha: 0,
            totalRegistro: 0,
            qtdRegistroPorPagina: 10,  

            data: [],

            filter: {},
            ordenar: 'id,asc',
            
            selecionado: {},

            formularioDialog: false,
            idIndicador: null,

            ativarOuInativarDialog: false,

            descricaoHistoricoDialog: "",
            historicoDialog: false,
            selecionado: {}
        }
    },

    created() {
        this.filter.situacao = "ATIVO";
        this.carregarDados();
    },

    methods: {

        carregarDados() {
            this.filter["page"] = this.pagina;
            this.filter["size"] = this.qtdRegistroPorPagina;
            this.filter["sort"] = this.ordenar;

            ManutencaoIndicadoresSerivce.buscarComPaginacao(this.filter)
                .then(({ data }) => {
                    if(data) {
                        this.data = data.content;
                        this.qtdRegistroPorPagina = data.pageable.pageSize;
                        this.totalRegistro = data.totalElements;
                    }
                })
                .catch(error => {
                    const toast = {
                        severity:'error',
                        summary: 'Falha ao buscar indicadores',
                        detail:`Falha não mapeada.`, 
                        life: 7500
                    };

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
                
            StorageService.setControlePaginaFilter("CONTRATO-COMPRA-VENDA", this.pagina, this.primeiraLinha, this.qtdRegistroPorPagina, this.filter);               
        },

        confirmarAtivarOuInativar(contrato) {
            this.selecionado = contrato;
            this.ativarOuInativarDialog = true;
        },

        ativarOuInativar() {
            if(this.selecionado.ativo) {
                ManutencaoIndicadoresSerivce.inativar(this.selecionado.id)
                    .then(response => {
                        this.$toast.add({
                            severity:'success',
                            summary: 'Inativado',
                            detail:`O indicador ${ this.selecionado.id } foi inativado!`, 
                            life: 7500
                        });

                        this.carregarDados();
                        this.ativarOuInativarDialog = false;
                        this.selecionado = {};
                    })
                    .catch(error => {
                        const toast = {
                            severity:'error',
                            summary: 'Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!',
                            detail: error, 
                            life: 7500
                        };

                        if(!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["summary"] = "Falha ao inativar.";
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
            }
            else {
                ManutencaoIndicadoresSerivce.ativar(this.selecionado.id)
                    .then(response => {
                        this.$toast.add({
                            severity:'success',
                            summary: 'Ativada',
                            detail:`O indicador ${ this.selecionado.id } foi ativado!`, 
                            life: 7500
                        });
                        
                        this.carregarDados();
                        this.ativarOuInativarDialog = false;
                        this.selecionado = {};
                    })
                    .catch(error => {
                        const toast = {
                            severity:'error',
                            summary: 'Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!',
                            detail: error, 
                            life: 7500
                        };

                        if(!!error && !!error.response && !!error.response.data) {
                            const { message } = error.response.data;
                            toast["summary"] = "Falha ao ativar.";
                            toast["detail"] = message;
                        }

                        this.$toast.add(toast);
                    });
            }
        },

        onPage(event) {
            this.pagina = event.page + 1;
            this.primeiraLinha = event.rows * event.page;
            this.qtdRegistroPorPagina = event.rows; 
            this.carregarDados();
        },

        sort(event) {
            const sortField = event.sortField;
            const sortOrder = event.sortOrder == 1 ? 'asc' : 'desc';
            this.ordenar = `${ sortField },${sortOrder}`;
            this.carregarDados();
        },

        cadastrar() {
            this.formularioDialog = true;
        },

        editar(id) {
            this.formularioDialog = true;
            this.idIndicador = id;
        },

        fecharFormulario() {
            this.formularioDialog = false;
            this.idIndicador = null;
            this.carregarDados();
        },

        limparFiltro() {
            this.filter["sigla"] = undefined;
            this.filter["descricao"] = undefined;
            this.carregarDados();
        },

        visualizarHistorico(obj) {
            this.descricaoHistoricoDialog = `Indicador: ${ obj.sigla } - ${ obj.descricao }`;
            this.historicoDialog = true;
            this.selecionado = obj;
        },

        ocultarHistorico() {
            this.historicoDialog = false;
            this.selecionado = {};
        }
    }
}
</script>

<style>
    .centralizar-titulo-tabela {
        text-align: center !important;
    }

    .centralizar-titulo-tabela .p-column-header-content {
        display: inline;
    }
</style>
