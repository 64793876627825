<template>
    <Dialog v-model:visible="visivel" :style="{width: '600px', margin: '10%'}" :modal="true" @hide="fechar()">
        <template #header>
            <div class="flex align-items-center mt-2">
                <h4 style="margin:0;">{{ !!id ? "Editar" : "Cadastrar" }} Indicador</h4>
            </div> 
        </template>

        <Message v-if="mensagem.ativo" :severity="mensagem.gravidade" @close="mensagem.ativo = false">{{ mensagem.conteudo }}</Message>

        <div class="grid p-fluid formgrid">
            <div class="col-4">
                <div class="field">
                    <label for="indicador">
                        <strong>Indicador</strong>
                    </label>
                    <InputText v-model="dados.id" class="w-full" :disabled="true" />
                </div>
            </div>

            <div class="col-8">
                <div class="field">
                    <label for="sigla">
                        <strong>Sigla<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputText v-model="dados.sigla" class="w-full" :class="{ 'p-invalid': campoInvalido.sigla }" />
                </div>
            </div>

            <div class="col-12">
                <div class="field">
                    <label for="descricao">
                        <strong>Descrição<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <InputText v-model="dados.descricao" class="w-full" :class="{ 'p-invalid': campoInvalido.descricao }" />
                </div>
            </div>

            <div class="col-4">
                <div class="field">
                    <label for="tipo">
                        <strong>Tipo<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <AutoComplete 
                        dropdown
                        placeholder="Selecione..." 
                        v-model="dados.tipo"
                        field="descricao"
                        id="tipo"
                        class="w-full"
                        :class="{ 'p-invalid': campoInvalido.tipo }"
                        :suggestions="tipoFiltro"
                        :forceSelection="true"
                        @complete="buscarTipo"
                    />
                </div>
            </div>

            <div class="col-3">
                <div class="field">
                    <label for="a-fixar">
                        <strong>A Fixar<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <AutoComplete 
                        dropdown
                        placeholder="Selecione..." 
                        v-model="dados.aFixar"
                        field="descricao"
                        id="a-fixar"
                        class="w-full"
                        :class="{ 'p-invalid': campoInvalido.aFixar }"
                        :suggestions="aFixarFiltro"
                        :forceSelection="true"
                        @complete="buscarAFixar"
                    />
                </div>
            </div>

            <div class="col-5">
                <div class="field">
                    <label for="utilizacao">
                        <strong>Utilização<span style="color:red;">&nbsp;*</span></strong>
                    </label>
                    <AutoComplete 
                        dropdown
                        placeholder="Selecione..." 
                        v-model="dados.utilizacao"
                        field="descricao"
                        id="utilizacao"
                        class="w-full"
                        :class="{ 'p-invalid': campoInvalido.utilizacao }"
                        :suggestions="utilizacaoFiltro"
                        :forceSelection="true"
                        @complete="buscarUtilizacao"
                    />
                </div>
            </div>
        </div>

        <template #footer>
            <div class="flex justify-content-center">
                <Button
                    icon="pi pi-save"
                    label="Salvar"
                    class="p-button-info ml-1"
                    @click="salvar()"
                ></Button>

                <Button
                    icon="pi pi-times"
                    label="Cancelar"
                    class="p-button-danger"
                    @click="fechar()"
                ></Button>
            </div>
        </template>
    </Dialog>
</template>

<script>
import ManutencaoIndicadoresSerivce from '../../service/ManutencaoIndicadoresSerivce';

export default {
    name: "ManutencaoIndicadoresForm",
    emits: ["fechar"],
    props: {
        visivel: {
            type: Boolean,
            required: true
        },
        id: {
            type: Number,
            required: false
        }
    },
    data() {
        return {
            unidadeFiltro: [],

            mensagem: { ativo: false, gravidade: 'info', conteudo: '' },

            campoInvalido: {},

            dados: {},

            tipoFiltro: [],
            tipos: [
                { valor: "DOLAR", descricao: "Dólar" },
                { valor: "PERCENTUAL", descricao: "Percentual" },
                { valor: "REAL", descricao: "Real" }
            ],

            aFixarFiltro: [],
            aFixarOpcoes: [
                { valor: true, descricao: "Sim" },
                { valor: false, descricao: "Não" },
            ],

            utilizacaoFiltro: [],
            utilizacoes: [
                { valor: "CONTRATO", descricao: "Contrato" },
                { valor: "COTACAO", descricao: "Cotação" },
                { valor: "FORMULA", descricao: "Fórmula" }
            ],
        }
    },
    methods: {
        buscarPorId(id) {
            ManutencaoIndicadoresSerivce.buscarPorId(id)
                .then(({ data }) => {
                    this.dados = data.indicadorValorDto;
                    this.dados["tipo"] = this.tipos.find(item => item.valor == this.dados.tipo);
                    this.dados["aFixar"] = this.aFixarOpcoes.find(item => item.valor == this.dados.aFixar);
                    this.dados["utilizacao"] = this.utilizacoes.find(item => item.valor == this.dados.utilizacao);
                })
                .catch(error => {
                    const toast = {
                        severity: 'error',
                        summary: 'Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!',
                        detail: error, 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["summary"] = "Não foi possível buscar o indicador.";
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        },

        isCamposObrigatoriosPreenchidos() {
            const camposInvalido = [];

            if(!this.dados.sigla) {
                this.campoInvalido.sigla = true;
                camposInvalido.push("Sigla");
            }
            else {
                this.campoInvalido.sigla = false;
            }

            if(!this.dados.descricao) {
                this.campoInvalido.descricao = true;
                camposInvalido.push("Descrição");
            }
            else {
                this.campoInvalido.descricao = false;
            }

            if(!this.dados.tipo) {
                this.campoInvalido.tipo = true;
                camposInvalido.push("Tipo");
            }
            else {
                this.campoInvalido.tipo = false;
            }

            if(!this.dados.aFixar) {
                this.campoInvalido.aFixar = true;
                camposInvalido.push("A Fixar");
            }
            else {
                this.campoInvalido.aFixar = false;
            }

            if(!this.dados.utilizacao) {
                this.campoInvalido.utilizacao = true;
                camposInvalido.push("Utilização");
            }
            else {
                this.campoInvalido.utilizacao = false;
            }

            if(camposInvalido.length > 0) {
                let mensagem = `Por favor, preencher o campo obrigatório: ${ camposInvalido.join(", ") }.`;

                if(camposInvalido.length > 1) {
                    mensagem = `Por favor, preencher os campos obrigatórios: ${ camposInvalido.join(", ") }.`;

                    const posicaoUltimaVirgula = mensagem.lastIndexOf(",");
                    if(posicaoUltimaVirgula > 0) {
                        mensagem = mensagem.substring(0, posicaoUltimaVirgula) + " e" + mensagem.substring(posicaoUltimaVirgula + 1);
                    }
                }

                this.mensagem = { ativo: true, gravidade: 'warn', conteudo: mensagem };
                return false;
            }

            return true;
        },

        limpar() {
            this.campoInvalido = {};
            this.dados = {};
        },

        limparMensagem() {
            this.mensagem = { ativo: false };
        },

        criarJson() {
            const json = {};
            json["id"] = this.id;
            json["sigla"] = this.dados.sigla;
            json["descricao"] = this.dados.descricao;
            json["tipo"] = this.dados.tipo.valor;
            json["aFixar"] = this.dados.aFixar.valor;
            json["utilizacao"] = this.dados.utilizacao.valor;
            return json;
        },

        salvar() {
            if(!this.isCamposObrigatoriosPreenchidos()) return;
            ManutencaoIndicadoresSerivce.salvar(this.criarJson())
                .then(({ data }) => {
                    this.$toast.add({
                        severity: 'success',
                        summary: 'Indicador salvo.',
                        detail: `O indicador ${ data.indicadorValorDto.id } foi salvo com sucesso.`, 
                        life: 7500
                    });
                    this.fechar();
                })
                .catch(error => {
                    const toast = {
                        severity: 'error',
                        summary: 'Ocorreu uma falha não mapeada, por favor entre em contato com o Administrador!',
                        detail: error, 
                        life: 7500
                    }

                    if(!!error && !!error.response && !!error.response.data) {
                        const { message } = error.response.data;
                        toast["summary"] = "Não foi possível salvar.";
                        toast["detail"] = message;
                    }

                    this.$toast.add(toast);
                });
        },

        fechar() {
            this.$emit("fechar");
            this.limpar();
            this.limparMensagem();
        },

        buscarTipo(event) {
            if (!event.query.trim().length) {
                this.tipoFiltro = [...this.tipos];
            } else {
                this.tipoFiltro = this.tipos.filter((item) => {
                    return item.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
        },

        buscarAFixar(event) {
            if (!event.query.trim().length) {
                this.aFixarFiltro = [...this.aFixarOpcoes];
            } else {
                this.aFixarFiltro = this.aFixarOpcoes.filter((item) => {
                    return item.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
        },

        buscarUtilizacao(event) {
            if (!event.query.trim().length) {
                this.utilizacaoFiltro = [...this.utilizacoes];
            } else {
                this.utilizacaoFiltro = this.utilizacoes.filter((item) => {
                    return item.descricao.toLowerCase().startsWith(event.query.toLowerCase());
                });
            }
        }
    },
    
    updated(){
        if(!!this.id) {
            this.buscarPorId(this.id);
        }
    }
}
</script>
